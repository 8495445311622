// src/pages/JobStatus.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';

const JobStatus = () => {
    const { jobId } = useParams();
    const [jobStatus, setJobStatus] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const response = await axios.get(`https://gitlab.com/api/v4/projects/:id/jobs/${jobId}`);
                setJobStatus(response.data.status);
            } catch (error) {
                setErrorMessage('Error fetching job status: ' + error.message);
                setIsModalVisible(true);
            }
        };

        const interval = setInterval(checkStatus, 5000);

        return () => clearInterval(interval);
    }, [jobId]);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <h1>Job Status: {jobStatus}</h1>

            <Modal
                title="Error"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
                okText="OK"
            >
                <p>{errorMessage}</p>
            </Modal>
        </div>
    );
};

export default JobStatus;