// src/BasicAuth.js
import React, { useState, useEffect } from 'react';

const BasicAuth = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Check if the user is already authenticated
        const savedAuth = localStorage.getItem('isAuthenticated');

        if (savedAuth === 'true') {
            setIsAuthenticated(true);
        } else {
            const username = prompt('Enter Username:');
            const password = prompt('Enter Password:');
            
            // Encode the credentials to Base64 (Basic Auth format)
            const credentials = btoa(`${username}:${password}`);
            
            // Basic credentials check (replace with your actual username and password)
            const validCredentials = btoa('benmax:B3CoJfR003qJVwE1ctAON5');
            
            if (credentials === validCredentials) {
                setIsAuthenticated(true);
                localStorage.setItem('isAuthenticated', 'true'); // Save authentication status
            } else {
                alert('Invalid credentials!');
                window.location.href = 'about:blank';
            }
        }
    }, []);

    if (!isAuthenticated) {
        return null; // Don't render anything until authenticated
    }

    return <>{children}</>;
};

export default BasicAuth;