// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import JobStatus from './pages/JobStatus';
import History from './pages/History';
import BasicAuth from './BasicAuth'; // Import the BasicAuth component


function App() {
    return (
        <React.StrictMode>
        <BasicAuth> 
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/job/:jobId" element={<JobStatus />} />
                    <Route path="/history" element={<History />} />
                </Routes>
            </Router>
        </BasicAuth>
    </React.StrictMode>
    );
}

export default App;