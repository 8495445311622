// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Input, Button, Typography, Space, Form, Row, Col, Card, notification, Table, Tag, Popconfirm } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { saveAs } from 'file-saver';
import { db } from '../firebase';

const { Title, Text } = Typography;
const Home = () => {
    const [subject, setSubject] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [generationHistory, setGenerationHistory] = useState([]); // State for video generation history

    // Load generation history from Firestore
    const loadGenerationHistory = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'generations'));
            const history = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setGenerationHistory(history);
        } catch (error) {
            console.error('Error fetching generation history:', error);
        }
    };

    useEffect(() => {
        loadGenerationHistory();
    }, []);

    // Fetch all pipelines from GitLab and compare with Firestore
    const updatePipelineStatuses = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_GITLAB_API_URL}/${process.env.REACT_APP_GITLAB_PROJECT_ID}/pipelines`,
                {
                    headers: {
                        'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_PRIVATE_TOKEN,
                    },
                }
            );

            const pipelines = response.data;
            const updatedHistory = generationHistory.map(entry => {
                const pipeline = pipelines.find(p => p.id === entry.pipelineId);
                if (pipeline && pipeline.status !== entry.status) {
                    updateDoc(doc(db, 'generations', entry.id), {
                        status: pipeline.status,
                    });
                    return { ...entry, status: pipeline.status };
                }
                return entry;
            });

            setGenerationHistory(updatedHistory);
        } catch (error) {
            console.error('Error fetching pipelines:', error);
        }
    };

    useEffect(() => {
        // Check pipeline statuses every 30 seconds
        const intervalId = setInterval(updatePipelineStatuses, 30000);
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [generationHistory]);

    const openNotification = () => {
        notification.success({
            message: 'Video Generation Started Successfully',
            description: 'Your video is being generated based on the provided subject.',
            placement: 'topRight',
        });
    };

    const triggerGeneration = async () => {
        setIsSubmitting(true);
        try {
            // Trigger the pipeline
            const pipelineResponse = await axios.post(
                `${process.env.REACT_APP_GITLAB_API_URL}/${process.env.REACT_APP_GITLAB_PROJECT_ID}/trigger/pipeline`,
                {
                    token: process.env.REACT_APP_GITLAB_TOKEN,
                    ref: 'main',
                    variables: {
                        SUBJECT: subject,
                        DEV: 'false',
                    },
                }
            );

            const pipelineId = pipelineResponse.data.id;

            // Fetch the jobs associated with the pipeline to get the jobId
            const jobsResponse = await axios.get(
                `${process.env.REACT_APP_GITLAB_API_URL}/${process.env.REACT_APP_GITLAB_PROJECT_ID}/pipelines/${pipelineId}/jobs`,
                {
                    headers: {
                        'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_PRIVATE_TOKEN,
                    },
                }
            );

            // Assume the first job in the pipeline is the one we are interested in (adjust as needed)
            const jobId = jobsResponse.data[0]?.id;

            // Save the new generation to Firestore
            await addDoc(collection(db, 'generations'), {
                pipelineId,
                jobId,
                subject,
                status: 'pending',
                createdAt: new Date(),
            });

            // Reset subject input
            setSubject('');

            openNotification();
            loadGenerationHistory(); // Reload the history
        } catch (error) {
            setErrorMessage('Error starting video generation: ' + error.message);
            setIsModalVisible(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'generations', id));
            setGenerationHistory(generationHistory.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };

    const handleDownload = (jobId, subject) => {
        const artifactUrl = `${process.env.REACT_APP_GITLAB_API_URL}/${process.env.REACT_APP_GITLAB_PROJECT_ID}/jobs/${jobId}/artifacts`;
    
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = artifactUrl;
        link.download = `${subject}.zip`; // Set the custom file name with the subject and .zip extension
        link.target = '_blank'; // Opens in a new tab/window
        link.rel = 'noopener noreferrer'; // Security feature for opening new windows
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns = [
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color = status === 'success' ? 'green' : status === 'failed' ? 'red' : 'blue';
                return <Tag color={color}>{status.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => new Date(date.seconds * 1000).toLocaleString(), // Firestore stores dates as timestamps
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownload(record.jobId, record.subject)}
                        disabled={record.status !== 'success'} // Disable if status is not success
                    />
                    <Popconfirm title="Are you sure delete this task?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                        <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Row justify="center" align="middle" style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
            <Col xs={24} sm={18} md={16} lg={14} xl={12}> {/* Larger width for video history */}
                <Card bordered={false} style={{ borderRadius: '10px', padding: '20px', marginBottom: '20px' }}>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
                            Generate TikTok Video
                        </Title>
                        <Form layout="vertical">
                            <Form.Item label={<Text strong>Enter Subject</Text>}>
                                <Input 
                                    placeholder="Type something interesting..."
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    disabled={isSubmitting}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button 
                                    type="primary" 
                                    block
                                    onClick={triggerGeneration}
                                    disabled={isSubmitting}
                                    size="large"
                                >
                                    {isSubmitting ? 'Generating...' : 'Generate'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </Card>

                <Card bordered={false} style={{ borderRadius: '10px', padding: '20px' }}>
                    <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>Video Generation History</Title>
                    <Table 
                        columns={columns}
                        dataSource={generationHistory}
                        rowKey="pipelineId"
                        pagination={{ pageSize: 5 }}
                    />
                </Card>
            </Col>

            <Modal
                title="Error"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
                okText="OK"
            >
                <p>{errorMessage}</p>
            </Modal>
        </Row>
    );
};

export default Home;