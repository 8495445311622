// src/pages/History.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const History = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get('https://gitlab.com/api/v4/projects/:id/jobs');
                setJobs(response.data);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJobs();
    }, []);

    return (
        <div>
            <h1>Job History</h1>
            <ul>
                {jobs.map(job => (
                    <li key={job.id}>
                        {job.name} - {job.status} - <a href={job.artifacts_url}>Download Artifact</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default History;